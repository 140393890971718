import { z, ZodRawShape, ZodObject, Primitive } from 'zod'

export const withAbility = <Key extends Primitive, ZodType extends ZodRawShape>(
  key: Key,
  schema: ZodObject<ZodType>
) => {
  return schema.extend({
    __typename: z.literal(key),
  })
}
